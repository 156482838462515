<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        Үндсэн ангилал
      </h3>
      <div class="action-section">
        <el-button
          type="success"
          size="mini"
          icon="el-icon-plus"
          @click="dialogFormVisible = true"
          >Нэмэх</el-button
        >
      </div>
    </div>
    <el-row :gutter="0">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <div align="right">
            <!-- <el-input
              style="width: 200px;"
              prefix-icon="el-icon-search"
              v-model="search"
              size="mini"
              placeholder="Хайх үйлчилгээний нэр"
            /> -->
          </div>
          <el-table
            :data="mainCategoryList"
            :default-sort="{ prop: 'date', order: 'descending' }"
            size="mini"
            style="width: 100%"
          >
            <el-table-column prop="name_mon" label="Нэр /монгол/">
            </el-table-column>
            <el-table-column prop="name_eng" label="Нэр /англи/">
            </el-table-column>
            <el-table-column prop="service" label="Төрөл"> </el-table-column>
            <el-table-column
              prop="createdAt"
              :formatter="formatter"
              label="Үүсгэсэн огноо"
            >
            </el-table-column>
            <el-table-column prop="sort" label="Эрэмбэ" sortable>
            </el-table-column>
            <el-table-column label="Үйлдэл">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="showEdit(scope.row)"
                  round
                ></el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="deleteMainCategory(scope.row)"
                  round
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="Апп дээрх категори" :visible.sync="dialogFormVisible">
      <el-form :model="mainCategory">
        <el-form-item label="Нэр /монгол/">
          <el-input
            v-model="mainCategory.name_mon"
            placeholder="категори монгол нэрийг оруулна уу"
          ></el-input>
        </el-form-item>
        <el-form-item label="Нэр /англи/">
          <el-input
            v-model="mainCategory.name_eng"
            placeholder="категори англи нэрийг оруулна уу"
          ></el-input>
        </el-form-item>
        <el-form-item label="Эрэмбэ">
          <el-input-number
            :min="0"
            v-model="mainCategory.sort"
            placeholder="категори монгол нэрийг оруулна уу"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="Категорийн төрөл">
          <el-select v-model="mainCategory.service" placeholder="төрөл сонгох">
            <el-option
              v-for="item in services"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Буцах</el-button>
        <el-button type="success" @click="addMainCategory()">Нэмэх</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Апп дээрх категори засах"
      width="30%"
      :visible.sync="dialogEditFormVisible"
    >
      <el-form :model="editMainCategory">
        <el-form-item label="Нэр /монгол/">
          <el-input
            v-model="editMainCategory.name_mon"
            placeholder="категори монгол нэрийг оруулна уу"
          ></el-input>
        </el-form-item>
        <el-form-item label="Нэр /англи/">
          <el-input
            v-model="editMainCategory.name_eng"
            placeholder="категори англи нэрийг оруулна уу"
          ></el-input>
        </el-form-item>
        <el-form-item label="Эрэмбэ">
          <el-input-number
            :min="0"
            v-model="editMainCategory.sort"
            placeholder="категори монгол нэрийг оруулна уу"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="Категорийн төрөл">
          <el-select
            v-model="editMainCategory.service"
            placeholder="төрөл сонгох"
          >
            <el-option
              v-for="item in services"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Буцах</el-button>
        <el-button type="success" @click="updateEdit()">Нэмэх</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import service from "@/helpers/services.js";
export default {
  created() {
    this.getSearchCategories();
  },
  data() {
    return {
      mainCategoryList: [],
      services: [
        {
          value: "food",
          label: "Хоол"
        },
        {
          value: "goods",
          label: "Ахуйн бараа"
        },
        {
          value: "drinks",
          label: "Шингэн хүнс"
        },
        {
          value: "grocery",
          label: "Хүнс"
        },
        {
          value: "gift",
          label: "Бэлэг"
        }
      ],
      dialogFormVisible: false,
      dialogEditFormVisible: false,
      mainCategory: {
        name_mon: "",
        name_eng: "",
        sort: 0,
        deleted: "",
        createdAt: "",
        updatedAt: "",
        service: ""
      },
      editMainCategory: {
        id: 0,
        name_mon: "",
        name_eng: "",
        sort: 0,
        deleted: "",
        createdAt: "",
        updatedAt: "",
        service: ""
      },
      deleteSendData: {
        id: 0
      }
    };
  },
  methods: {
    filterTag(value, row) {
      return row.is_active === value;
    },
    closeProgress() {
      this.dialogFormVisible = false;
      this.dialogEditFormVisible = false;
    },
    getSearchCategories() {
      service.getCategories1().then(res => {
        this.mainCategoryList = res.data.data;
      });
    },
    formatter(row) {
      var convertDate = row.createdAt.substring(-6, 10);
      return convertDate;
    },
    addMainCategory() {
      this.dialogFormVisible = true;
      var today = new Date();
      this.mainCategory.createdAt =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      service.createMainCategory(this.mainCategory).then(response => {
        if (response.id !== null) {
          this.$notify({
            title: "Амжилттай",
            message: "Категори мэдээлэл шинээр нэмэгдлээ",
            type: "success"
          });
          this.closeProgress();
          this.getSearchCategories();
        } else if (response.id == null) {
          this.$notify({
            title: "Амжилтгүй",
            message:
              "Категори мэдээлэл нэмэхэд алдаа гарлаа " +
              " " +
              response.error.message,
            type: "warning"
          });
        }
      });
    },
    showEdit(row) {
      this.editMainCategory = row;
      this.editMainCategory.service = row.service;
      this.dialogEditFormVisible = true;
    },
    updateEdit() {
      var today = new Date();
      this.editMainCategory.updatedAt =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      service.updateMainCategory(this.editMainCategory).then(response => {
        if (response.status === "success") {
          this.$notify({
            title: "Амжилттай",
            message: "Категорийн мэдээлэл засагдлаа",
            type: "success"
          });
          this.closeProgress();
        } else if (response.status === "unsuccess") {
          this.$notify({
            title: "Амжилтгүй",
            message:
              "Категорийн мэдээлэл засахад алдаа гарлаа " +
              " " +
              response.error.message,
            type: "warning"
          });
        }
      });
    },
    deleteMainCategory(data) {
      this.deleteSendData.id = data.id;
      this.$confirm("Устгахдаа итгэлтэй байна уу?", data.name_mon, {
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        type: "warning"
      })
        .then(() => {
          service.deleteMainCategory(this.deleteSendData).then(response => {
            if (response.status === "success") {
              this.$notify({
                title: "Амжилттай",
                message: "Категори устгагдлаа",
                type: "success"
              });
              this.getSearchCategories();
            } else if (response.status === "unsuccess") {
              this.$notify({
                title: "Амжилтгүй",
                message:
                  "Категори устгахад алдаа гарлаа " +
                  " " +
                  response.error.message,
                type: "warning"
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Буцлаа"
          });
        });
    }
  }
};
</script>
